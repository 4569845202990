// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-en-js": () => import("./../src/templates/index-page-en.js" /* webpackChunkName: "component---src-templates-index-page-en-js" */),
  "component---src-templates-index-page-fr-js": () => import("./../src/templates/index-page-fr.js" /* webpackChunkName: "component---src-templates-index-page-fr-js" */),
  "component---src-templates-dj-fr-js": () => import("./../src/templates/dj-fr.js" /* webpackChunkName: "component---src-templates-dj-fr-js" */),
  "component---src-templates-dj-en-js": () => import("./../src/templates/dj-en.js" /* webpackChunkName: "component---src-templates-dj-en-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-404-js": () => import("./../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-success-js": () => import("./../src/pages/en/success.js" /* webpackChunkName: "component---src-pages-en-success-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

